import Surrounding1 from "./surrounding (1).png";
import Surrounding2 from "./surrounding (2).png";
import Surrounding3 from "./surrounding (3).png";
import Surrounding4 from "./surrounding (4).png";
import Surrounding5 from "./surrounding (5).png";

const surrounding = [
  Surrounding1,
  Surrounding2,
  Surrounding3,
  Surrounding4,
  Surrounding5,
];

export default surrounding;
