import React from "react";
import "./surrounding.scss";
import surrounding from "../../asset/surrounding/surrounding";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Surrounding = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    autoplay: true,
    speed: 1000,

    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1,
          arrows: false,
          lazyload: true,
          autoplay: true, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  return (
    <div className="surrounding">
      <div className="container-surrounding">
        <div className="container">
          <div className="header-surrounding">
            <div className="judul-surrounding">SURROUNDING AREA</div>
          </div>
          <div className="carousel-surrounding">
            <div className="carousel-produk">
              <Slider {...settings}>
                {surrounding.map((image, index) => (
                  <img
                    className="kartugambar"
                    key={index}
                    src={image}
                    alt="fasilitas"
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Surrounding;
