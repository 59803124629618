import Pure1 from "./Pure-1.webp";
import Pure3 from "./Pure-3.webp";
import Pure4 from "./Pure-4.webp";
import Pure5 from "./Pure-5.webp";
import Pure6 from "./Pure-6.webp";
import Pure7 from "./Pure-7.webp";
import Pure8 from "./Pure-8.webp";
import Pure9 from "./Pure-9.webp";
import Pure10 from "./Pure-10.webp";

const pure = [Pure1, Pure3, Pure4, Pure5, Pure6, Pure7, Pure8, Pure9, Pure10];

export default pure;
