import Fresh2 from "./Fresh-2.webp";
import Fresh3 from "./Fresh-3.webp";
import Fresh4 from "./Fresh-4.webp";
import Fresh5 from "./Fresh-5.webp";
import Fresh6 from "./Fresh-6.webp";
import Fresh7 from "./Fresh-7.webp";
import Fresh8 from "./Fresh-8.webp";
import Fresh9 from "./Fresh-9.webp";
import Fresh10 from "./Fresh-10.webp";
import Fresh11 from "./Fresh-11.webp";
import Fresh12 from "./Fresh-12.webp";
import Fresh13 from "./Fresh-13.webp";

const fresh = [
  Fresh2,
  Fresh3,
  Fresh4,
  Fresh5,
  Fresh6,
  Fresh7,
  Fresh8,
  Fresh9,
  Fresh10,
  Fresh11,
  Fresh12,
  Fresh13,
];

export default fresh;
