import React from "react";
import "./produk.scss";
import Card from "./card/card.jsx";
import Ruko from "./ruko/ruko.jsx";

const Produk = () => {
  return (
    <div id="produk" className="produk">
      <div className="container-produk">
        <div className="container">
          <div className="header-produk">
            <div className="judul-produk">Our Masterpiece</div>
          </div>
        </div>
        <Card />
        <Ruko />
      </div>
      <div className="dividera"></div>
    </div>
  );
};

export default Produk;
