import Prime1 from "./Prime-1.webp";
import Prime2 from "./Prime-2.webp";
import Prime3 from "./Prime-3.webp";
import Prime4 from "./Prime-4.webp";
import Prime5 from "./Prime-5.webp";
import Prime6 from "./Prime-6.webp";
import Prime7 from "./Prime-7.webp";
import Prime8 from "./Prime-8.webp";
import Prime9 from "./Prime-9.webp";
import Prime10 from "./Prime-10.webp";
import Prime11 from "./Prime-11.webp";
import Prime12 from "./Prime-12.webp";
import Prime13 from "./Prime-13.webp";

const prime = [
  Prime1,
  Prime2,
  Prime3,
  Prime4,
  Prime5,
  Prime6,
  Prime7,
  Prime8,
  Prime9,
  Prime10,
  Prime11,
  Prime12,
  Prime13,
];

export default prime;
