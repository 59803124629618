import Harmony1 from "./Harmony (1).webp";
import Harmony2 from "./Harmony (2).webp";
import Harmony3 from "./Harmony (3).webp";
import Harmony4 from "./Harmony (4).webp";
import Harmony5 from "./Harmony (5).webp";
import Harmony6 from "./Harmony (6).webp";
import Harmony7 from "./Harmony (7).webp";
import Harmony8 from "./Harmony (8).webp";
import Harmony9 from "./Harmony (9).webp";
import Harmony10 from ".//Harmony (10).webp";
import Harmony11 from ".//Harmony (11).webp";

const harmony = [
  Harmony1,
  Harmony2,
  Harmony3,
  Harmony4,
  Harmony5,
  Harmony6,
  Harmony7,
  Harmony8,
  Harmony9,
  Harmony10,
  Harmony11,
];

export default harmony;
