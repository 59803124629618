import React from "react";
import card from "./card.json";
import "./card.scss";
import Cardrumah from "../../../component/cardrumah/cardrumah.jsx";
import clarity from "../../../asset/clarity/clarity.js";
import fresh from "../../../asset/fresh/fresh.js";
import harmony from "../../../asset/harmony/harmony.js";
import pure from "../../../asset/pure/pure.js";
import luminous from "../../../asset/luminous/luminous.js";
import prime from "../../../asset/prime/prime.js";
const imageMap = {
  clarity,
  fresh,
  harmony,
  pure,
  luminous,
  prime,

  // Add other image arrays here if you have more sets
};

const Card = () => {
  return (
    <div id="rumah" className="card">
      <div className="container-card">
        <div className="header">
          <div className="judul-card">
            <div className="pink"></div>
            <span>OUR HOME</span>
          </div>
        </div>
        <div className="cardhome-container">
          {card.cards.map((card, index) => {
            // Determine the logo dynamically
            return (
              <Cardrumah
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Card;
