import Clarity2 from "./Clarity-2.webp";
import Clarity3 from "./Clarity-3.webp";
import Clarity4 from "./Clarity-4.webp";
import Clarity5 from "./Clarity-5.webp";
import Clarity6 from "./Clarity-6.webp";
import Clarity7 from "./Clarity-7.webp";
import Clarity8 from "./Clarity-8.webp";
import Clarity9 from "./Clarity-9.webp";
import Clarity10 from "./Clarity-10.webp";
import Clarity11 from "./Clarity-11.webp";

const clarity = [
  Clarity2,
  Clarity3,
  Clarity4,
  Clarity5,
  Clarity6,
  Clarity7,
  Clarity8,
  Clarity9,
  Clarity10,
  Clarity11,
];

export default clarity;
