import React from "react";
import "./Lokasi.scss";
import gambarmobile from "./mapmobile.jpeg";
import gambarlokasi from "./dekstop.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const Lokasi = () => {
  const walokasi = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(LOKASI)%20https://marketingcitragardenserpong.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="lokasi-container" id="Lokasi">
      <div className="lokasi-inner">
        <div id="lokasi" className="lokasi-title">
          LOKASI
        </div>
        <div className="image-lokasi">
          <picture className="gambar">
            <source
              media="(max-width: 768px)"
              srcSet={gambarmobile}
              width="320"
            />
            <source
              media="(min-width: 768px)"
              srcSet={gambarlokasi}
              width="600"
            />
            <img className="gambar" src={gambarlokasi} alt="lokasi" />
          </picture>
          <button className="button" onClick={walokasi}>
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp; Whatsapp
          </button>
        </div>
        <div className="lokasi-list">
          <li>
            <FontAwesomeIcon icon={faLocationDot} />
            &nbsp;&nbsp;5 Menit ke ICE BSD
          </li>
          <li>
            <FontAwesomeIcon icon={faLocationDot} />
            &nbsp;&nbsp;5 Menit ke Universitas Prastiya Mulya
          </li>
          <li>
            <FontAwesomeIcon icon={faLocationDot} />
            &nbsp;&nbsp;5 Menit ke Rumah Sakit Selaras
          </li>
          <li>
            <FontAwesomeIcon icon={faLocationDot} />
            &nbsp;&nbsp;6 Menit ke AEON BSD{" "}
          </li>
          <li>
            <FontAwesomeIcon icon={faLocationDot} />
            &nbsp;&nbsp;7 Menit ke Pintu Tol Serpong
          </li>
          <li>
            <FontAwesomeIcon icon={faLocationDot} />
            &nbsp;&nbsp;7 Menit ke The Breeze
          </li>
          <li>
            <FontAwesomeIcon icon={faLocationDot} />
            &nbsp;&nbsp;10 Menit ke Ocean Park BSD
          </li>
          <li>
            <FontAwesomeIcon icon={faLocationDot} />
            &nbsp;&nbsp;10 Menit ke Eka Hospital
          </li>
          <li>
            <FontAwesomeIcon icon={faLocationDot} />
            &nbsp;&nbsp;10 Menit ke Taman Kota BSD
          </li>
          <li>
            <FontAwesomeIcon icon={faLocationDot} />
            &nbsp;&nbsp;20 Menit ke Universitas Pelita Harapan
          </li>
        </div>
      </div>
    </div>
  );
};

export default Lokasi;
