import React from "react";
import "./about.scss";
import { PiHouseLine } from "react-icons/pi";
import { BsCreditCard } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const about = () => {
  const waabout = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(about)%20https://marketingcitragardenserpong.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const wabrosur = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(brosur)%20https://marketingcitragardenserpong.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="about" className="about">
      <div className="container-about">
        <div className="header-about">
          <div className="judul-about">Citra Garden Serpong CGS </div>
          <div className="subjudul-about">Gading Serpong, Banten</div>
          <div className="sec-about">
            <div className="home-about">
              <div className="icon-home-about">
                <PiHouseLine />
              </div>
              <div className="ket-home">
                <div className="judul-home-about">Tipe Propety</div>
                <div className="subjudul-home-about">
                  Rumah Ready Dan Indent
                </div>
              </div>
              <div className="button-home-about">
                <button className="brosur" onClick={wabrosur}>
                  Brosur
                </button>
              </div>
            </div>
            <div className="pembayaran-about">
              <div className="icon-pembayaran-about">
                <BsCreditCard />
              </div>
              <div className="ket-pembayaran">
                <div className="judul-pembayaran-about">Jenis Pembayaran</div>
                <div className="subjudul-pembayaran-about">
                  Cash dan KPR 100% Approved
                </div>
              </div>
              <div className="button-pembayaran-about">
                <button className="carda" onClick={waabout}>
                  <span className="waicon">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </span>
                  &nbsp; Whatsapp
                </button>
              </div>
            </div>
          </div>
          <div className="about-us">
            <div className="judul-about-us">
              Investasi Properti Terbaik di Jantung Serpong
            </div>
            <div className="subjudul-about-us">
              Citra Garden Serpong adalah sebuah perumahan dari Ciputra Group
              yang terletak di Tangerang. Citra Garden Serpong dikembangkan
              dengan konsep Back to Nature dan Eco Green. Dengan Tujuan untuk
              menciptakan lingkungan yang sehat, nyaman, dan ramah lingkungan
              bagi penghuninya. Citra Garden Serpong juga mengusung tema New
              Living Experience. Citra Garden Serpong memiliki berbagai macam
              fasilitas, seperti Swimming Pool, Sports Arena, Area Komersil,
              Green Park untuk mendukung konsep eco green.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
