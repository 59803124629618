import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import { FloatingWhatsApp } from "react-floating-whatsapp";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
      <a
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=6282288889274&text=Halo%20Cornel,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20()%20https://marketingcitragardenserpong.com//&type=phone_number&app_absent=0"
      >
        <FloatingWhatsApp />
      </a>
    </div>
  );
};

export default App;
