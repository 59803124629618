import React from "react";
import rukocard from "./ruko.json";
import "./ruko.scss";
import Cardrumah from "../../../component/cardrumah/cardrumah.jsx";
import ruko from "../../../asset/ruko/ruko.js";
const imageMap = {
  ruko,
};

const Ruko = () => {
  return (
    <div id="ruko" className="ruko">
      <div className="container-ruko">
        <div className="header">
          <div className="judul-ruko">
            <div className="pink"></div>
            <span>La Valle Boulevard</span>
          </div>
        </div>
        <div className="cardhome-container">
          {rukocard.cards.map((card, index) => {
            // Determine the logo dynamically
            return (
              <Cardrumah
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Ruko;
