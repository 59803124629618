import React from "react";
import "./footer.scss";
import logo from "../../asset/main/logo.png";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-pik" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h2>Marketing Gallery</h2>
            <h1>Citra Garden Serpong</h1>
            <p>
              Jl. Raya Cisauk Lapan, Cisauk, Kec. Cisauk, Kabupaten Tangerang,
              Banten
            </p>
          </div>
          <div className="contact">Contact Us : +6282288889274</div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">CITRA GARDEN SERPONG</div>
    </div>
  );
};

export default footer;
