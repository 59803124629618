import Luminous2 from "./Luminous-2.webp";
import Luminous3 from "./Luminous-3.webp";
import Luminous4 from "./Luminous-4.webp";
import Luminous5 from "./Luminous-5.webp";
import Luminous6 from "./Luminous-6.webp";
import Luminous7 from "./Luminous-7.webp";
import Luminous8 from "./Luminous-8.webp";
import Luminous9 from "./Luminous-9.webp";

const luminous = [
  Luminous2,
  Luminous3,
  Luminous4,
  Luminous5,
  Luminous6,
  Luminous7,
  Luminous8,
  Luminous9,
];

export default luminous;
